import React from 'react';
import { bool, shape, string } from 'prop-types';
import { loadable } from 'nordic/lazy';
import { RecommendationsCarouselSnapped } from '@recommendations-fe/carousel-snapped';
import RecommendationsIconFactory from './icon-factory';
import ContextTemplate from './polycard-context';
import { RecommendationsCardHorizontalTitleFirst } from './recomendations-card';

const Polycard = loadable(() => import('../polycard/polycard'));

Polycard.preload();

const Recommendations = ({ isPolycard, thousandSeparator, decimalSeparator, ...props }) => {
  const CardTemplate = cardProps =>
    isPolycard ? (
      <Polycard polycard={{ ...cardProps }} className="recos-polycard poly-card--mobile" />
    ) : (
      <RecommendationsCardHorizontalTitleFirst {...cardProps} />
    );

  return (
    <RecommendationsCarouselSnapped
      Card={CardTemplate}
      Context={ContextTemplate}
      contextProps={{
        type: 'list-card',
        thousandSeparator,
        decimalSeparator,
      }}
      isPolycard={isPolycard}
      IconFactory={RecommendationsIconFactory}
      {...props}
    />
  );
};

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
  className: string,
  apiresponse: shape({}),
};

export default Recommendations;
